import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { ButtonLink } from "../components/Buttons";
import { 
  BorderDiv,
  HeaderH1, 
  HeaderH3,
  SectionH2,
  SectionH4,
  SectionP,
} from "../helpers/styledComponents";
import { Grid, Button } from "semantic-ui-react";
import styled from "styled-components";

const headerContent = (
  <>
    <HeaderH1>Resources and Events</HeaderH1>
    <div style={{maxWidth: "900px", margin: "auto"}}>
      <HeaderH3>We support the business community with tools, resources, and events to help craft their nonpartisan civic initiatives. We also offer a Civic Concierge service that provides coaching and program planning available to members.</HeaderH3>
      <HeaderH3>Members have access to a whole suite of resources, from evergreen civic messaging tools and a quick start guide to stand up civic programming, to communications guidance for key civic moments and reactive social media toolkits. Most of our resources are developed exclusively for members.</HeaderH3>
    </div>
  </>
);

const SectionH4Big = styled(SectionH4)`
  font-size: 1.5rem;
`;

const StyledUL = styled.ul`
  font-size: 1.25em;
  list-style: outside disc;
  margin-left: 2em;
  margin-top: 1em;
  margin-bottom: 0;
  padding-left: 0; 

  li+li {
    margin-top: .25em;
  }
`;

export default () => {
  return (
    <Layout 
      mode="dark" 
      headerContent={headerContent}
      title="Member Resources and Events | Civic Alliance"
    >
      <Section mode="light" extraMargin>
        <SectionH2>Resources &amp; Toolkits</SectionH2>
          <Grid columns={2} stackable>
            <Grid.Column style={{ width: "45%", margin: "40px auto", padding: "30px", border: "1px solid black", borderRadius: "30px"}} textAlign="center">
              <SectionH4Big>2023 Quick Start Guide</SectionH4Big>
              <SectionP center>
              Build and launch your civic plans for this year with our 2023 Quick Start Guide. It has our latest recommendations for you to create a voter-friendly workplace, encourage your audiences to vote, go beyond voting, and share your civic story.
              </SectionP>
              <Button style={{ color: "rgb(249, 249, 249)", backgroundColor: "rgb(10, 10, 10)" }} href="https://cvcallnce.com/2023QSGwebsite">Get the Quick Start Guide</Button>
            </Grid.Column>
            <Grid.Column style={{ width: "45%", margin: "40px auto", padding: "30px", border: "1px solid black", borderRadius: "30px"}} textAlign="center">
              <SectionH4Big>2024 Elections Toolkit</SectionH4Big>
              <SectionP center>
              Everything you need to champion local and state elections, educate your audiences about the elected positions + key races on their ballots, and connect employees and customers with valuable voter resources.
              </SectionP>
              <Button style={{ color: "rgb(249, 249, 249)", backgroundColor: "rgb(10, 10, 10)" }} href="https://drive.google.com/file/d/13UZ_Kyhi8y0w9WQvDWraX47CtURomCc1/view?_hsmi=289796621&_hsenc=p2ANqtz-9YVzlczPNqakHbsO-x-sMdsTduhCoGXUZiPZv4uMm6j_VDbFJbNU3dGNkYd5qv7DRt0EEYve3t7zTN8urnOqZBOzb_cw" target="_blank">Check out the 2024 Elections Toolkit</Button>
            </Grid.Column>
          </Grid>
          <SectionH4Big>Civic Storylines Toolkit</SectionH4Big>
          <SectionP center>
            Our resource to help content creators authentically and impactfully integrate civic themes into their filmes, TV shows, podcast episodes and more! Developed in partnership with CAA, Comedy Central, MTV and Spotify.
            <br />
            <br />
            <ButtonLink mode="dark" to="/storylines" newTab>Get the Storylines Toolkit</ButtonLink>
          </SectionP>
      </Section>
      <Section mode="dark" larger>
        <SectionH2>Civic Alliance Events</SectionH2>
        <SectionP>We support the business community with a range of virtual events and briefings featuring experts, business leaders, and non-profit partners. The Civic Alliance community also enjoys opportunities to connect with their peers in the business sector to learn and grow together.</SectionP>
        {/* Button href to be added here*/}
        <ButtonLink mode="light" to="https://share.hsforms.com/1LaUUUijGRW6g-3VqvsHqYwd7bz3" newTab={true} center>Join newsletter to receive latest event updates</ButtonLink>
        <BorderDiv mode="dark" style={{ margin: "2.5rem auto 0" }}>
          <SectionP>Past event topics include:</SectionP>
          <StyledUL>
            <li>2022 Midterm Elections: lessons learned from the primaries</li>
            <li>Tackling Misinformation Ahead of Election Day</li>
            <li>Cultivating a Workplace Culture of Belonging</li>
            <li>Stadiums As Effective Polling Sites</li>
          </StyledUL>
        </BorderDiv>
      </Section>
    </Layout>
  );
}